import React, { ForwardedRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import ReactMarkdown from 'react-markdown';
import Link, { LinkProps } from 'next/link';
import Image from './Image';
import { HandbookChapterFragment } from '../schemas/api';

import MarkdownStyle from '../styles/markdown';
import BREAKPOINTS from '../styles/breakpoints';
import { columns } from '../styles/grid';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';

import HandbookMailchimpForm from './HandbookMailchimpForm';

const FOOTER_IMAGE_HEIGHT = 22;

const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 70%;
  max-width: 620px;
  padding: 24px 60px;
  height: 100%;
  z-index: 1;
  position: relative;
  ${BREAKPOINTS.max.small`
  padding: 24px 24px 0;
  width: 100%;
  height: auto;
  `}
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 800px;
`;

const Whitepaper = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  background: ${COLORS.shades.s200.css};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px),
    ${COLORS.shades.s200.css};
  background-size: 24px 24px;
  & > img {
    height: 100%;
    margin: 0 auto;
    border: 1px solid ${COLORS.white.css};
    border-radius: 3px;
    box-shadow: 6px 6px 8px ${COLORS.black.opacity(0.1)};
  }
`;

type ImagePreviewProps = {
  $src: string;
};

const ImagePreview = styled.div<ImagePreviewProps>`
  background-image: ${(props) => `url('${props.$src}')`};
  width: 100%;
  max-width: 580px;
  height: 100%;

  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-left: auto;
  pointer-events: none;
  z-index: 0;

  ${BREAKPOINTS.max.small`
  position: relative;
  height:200px;
  `}
`;

const Title = styled.div`
  ${TYPO.h3};
  font-size: 28px;
`;

const Text = styled.div`
  ${TYPO.p1};
  font-size: 14px;

  ${MarkdownStyle}
  & > p {
    line-height: 140%;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: auto;
  ${BREAKPOINTS.max.small`
  margin-top: 0;
  `}
`;

const Tag = styled.div`
  ${TYPO.strong};
  font-size: 12px;
  padding: 1px 0;
  align-self: flex-start;
  border-radius: 2px;
  color: ${COLORS.brand.regular.css};
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
`;

const ItemStyle = css`
  ${columns(1, 12)};
  display: flex;

  gap: 12px;

  box-shadow: 0 0 20px ${COLORS.brand.regular.opacity(0.05)};
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.brand.light.css};
  border-radius: 6px;
  overflow: hidden;

  background-position: bottom right;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 240px;

  ${BREAKPOINTS.max.small`
  flex-direction: column;
  height: auto;
  `}
`;

const ItemLink = styled(Link)`
  ${ItemStyle};
  cursor: pointer;
  &:hover {
    border-color: ${COLORS.brand.regular.css};
    ${ImagePreview} {
      opacity: 0.9;
    }
    ${Whitepaper} {
      opacity: 0.9;
    }
  }
`;
const ItemComingSoon = styled.div`
  ${ItemStyle}
`;

const Logos = styled.div`
  display: flex;
  gap: 24px;
`;

type BlogPostPreviewProps = {
  post: HandbookChapterFragment;
  href: LinkProps['href'];
};

function HandbookPostPreview(
  { post, href }: BlogPostPreviewProps,
  ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>
) {
  if (post.comingSoon) {
    return (
      <ItemComingSoon
        data-coming-soon
        ref={ref as ForwardedRef<HTMLDivElement>}
        aria-label={post.title}
      >
        <PostInfo>
          <Metadata>
            {post.preHeading && <Tag>{post.preHeading}</Tag>}
            <Title>{post.title}</Title>
            <Text>
              <ReactMarkdown linkTarget="_blank">
                {post.previewText}
              </ReactMarkdown>
            </Text>
          </Metadata>
          <HandbookMailchimpForm title={post.title} />
        </PostInfo>
        <ImagePreview $src={post.previewImage?.url} />
      </ItemComingSoon>
    );
  }

  return (
    <ItemLink
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      aria-label={post.title}
      href={href}
      rel="preload"
      prefetch
    >
      <PostInfo>
        <Metadata>
          {post.preHeading && <Tag>{post.preHeading}</Tag>}
          <Title>{post.title}</Title>
          <Text>
            <ReactMarkdown linkTarget="_blank">
              {post.previewText}
            </ReactMarkdown>
          </Text>
        </Metadata>
        <Footer>
          <Logos>
            {post.logos?.items.map((d) => {
              const ratio = d.logo.width / d.logo.height;
              return (
                <Image
                  src={d.logo.url}
                  width={Math.floor(FOOTER_IMAGE_HEIGHT * ratio)}
                  height={FOOTER_IMAGE_HEIGHT}
                  alt={d.name}
                  key={d.logo.url}
                  priority
                />
              );
            })}
          </Logos>
        </Footer>
      </PostInfo>
      <ImagePreview $src={post.previewImage?.url} />
    </ItemLink>
  );
}

export default forwardRef<HTMLAnchorElement, BlogPostPreviewProps>(
  HandbookPostPreview
);
